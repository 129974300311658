<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-21 15:27:23
 * @LastEditTime: 2021-03-02 12:42:53
 * @FilePath: \acuconference-web\src\components\dialogs\editConference.vue
-->
<template>
   
  <v-dialog
    v-model="exportShow"
    max-width="350"
  >
    <v-card >
      <v-card-title style="justify-content:center;word-break:unset;">
        {{$t("label_title_sendEmail")}}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row style="justify-content:center;">
              <v-col cols="12" md="11">
                 <v-text-field
                  :placeholder="$t('label_input_label_email_address')"
                  maxLength="500"
                  v-model="emailImput"
                  :rules="checkAccount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="exportShow = false">
          {{ $t("label_btn_cancel") }}
        </v-btn>
        <v-btn
          text
          color="red"
          @click="confirmDeleteConference"
          v-if="editConference.id"
        >
          {{ $t("label_btn_delete") }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :disabled="!emailImput"
          @click="submit(emailImput)"
        >
          {{$t("label_btn_login_submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import regxCheckMixins from "../../mixins/regCheckRulesMixin";
export default {
     mixins: [regxCheckMixins],
  props: {
    conference: {
      type: Object,
      default() {
        return {};
      },
    },
    value: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      editStatus: false,
      canSubmit: false,
      valid: false,
      exportShow: false,
      emailImput:"",
      email:"",
      editConference: {
        roomInfo: {},
      },

    };
  },
  watch: {
    value(val) {
      if (this.exportShow !== val) {
        this.exportShow = val;
      }
      this.$nextTick(() => {
        this.editStatus = this.value;
      });
    },
    exportShow(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
    },
  },
  methods: {
    closeEditDialog() {
      this.$emit("finishEditConference");
      this.$nextTick(() => (this.exportShow = false));
    },
    async submit(val) {
        const reg = new RegExp(
          "^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"
        );
        if (reg.test(val)) {
          // 满足邮箱格式
          this.email = val;
          this.$emit('emailValue',this.email)
          this.$nextTick(() => this.closeEditDialog());
        }
    },
    
  },
};
</script>

<style lang="sass" scoped>
.inputFlex
  justify-content:center
.row
  display: flex
  justify-content:center
</style>