<template>
  <v-card :disabled="loading">
    <v-card-text>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" sm="3">
              <v-select
                :items="moduleSelect"
                v-model="moduleSelects"
                item-text="label"
                item-value="id"
                :label="$t('label_select_label_module')"
                @change="moduleSelectClick(moduleSelects)"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-select
                v-if="moduleShow == 10 || moduleShow == 20"
                :items="
                  moduleShow == 10? configurationSelectChild: (moduleShow == 20?managementSelectChild:'')
                "
                v-model="moduleSelectChilds"
                item-text="label"
                item-value="id"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="actionSelect"
                v-model="actionSelects"
                item-text="label"
                item-value="id"
                :label="$t('label_select_labal_action')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="clientIp"
                :label="$t('label_input_label_clientIP')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="domain"
                :label="$t('label_normal_Domain')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="account"
                :label="$t('label_normal_account')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="description"
                :label="$t('label_description_keyword')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    multiple
                    chips
                    small-chips
                    :label="$t('label_select_label_operation_data')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dates" range no-title>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t("label_btn_cancel") }}
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dates)">
                    {{ $t("label_btn_confirm") }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="btns">
              <v-btn
                color="primary"
                style="margin-left: 25px"
                @click="exportShow = true"
              >
                {{ $t("label_btn_export") }}
              </v-btn>
              <v-btn color="primary" @click="search">
                {{ $t("label_btn_search") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            v-show="pageShow===0"
            style="width: 100%"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            hide-default-footer
            
            ><template v-slot:no-data>
              <span> </span> </template
          ></v-data-table>
          <v-data-table
            v-show="pageShow===1"
            style="width: 100%"
            :loading="loading"
            loading-text="Loading... Please wait"
            :items-per-page="limit"
            :headers="headers"
            :items="list"
            :server-items-length="totalCount"
            :page="page"
            :must-sort="true"
            @update:page="pageUpdate"
            @update:items-per-page="limitUpdate"
            @update:sort-by="getsortBy"
            @update:sort-desc="getsortDesc(sorts)"
            :footer-props="tableFooterOptions"
            class="elevation-1"
            @click:row="row"
          >
            <template v-slot:no-data>
              <span v-if="flagshow">
                {{ $t("table_no_data") }}
              </span>
              <span v-else> </span>
            </template>
            <template v-slot:item.createTime="{ item }">
              <span>
                {{ formatDetailTime(item.createTime) }}
              </span>
            </template>
            <template v-slot:item.module="{ item }">
               <span
                :title="
                  item.module +
                  (item.subModule != '' ? colon : '') +
                  item.subModule
                "
              >
                {{ subflag === 0 ? item.module : item.subModule }}
              </span>
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-col v-bind="attrs" v-on="on">
                    <span>
                      {{ subflag===0?item.module:item.subModule }}
                    </span>
                  </v-col>
                </template>
                <span>{{ item.module }}<span v-show="item.subModule!=''">:{{item.subModule}}</span></span>
              </v-tooltip> -->
            </template>
            <template v-slot:item.action="{ item }">
              <span>
                {{ item.action }}
              </span>
            </template>
            <template v-slot:item.description="{ item }">
              <span class="des" :title="item.description | replaceLogString">{{
                item.description
              }}</span>
            </template>
          </v-data-table>
        </v-container>
      </v-form>
    </v-card-text>
    <ExportDialog v-model="exportShow" @emailValue="emailValue" />
  </v-card>
</template>
<script>
import moment from "moment";
import apis from "../../mixins/apis";
import timeFormatFilter from "../../mixins/timeFormatFilter";
import ExportDialog from "../../components/viewlog/exportDialog";
export default {
  mixins: [apis, timeFormatFilter],
  components: {
    ExportDialog,
  },
  data() {
    return {
      flag: false,
      flagshow: false,
      menu: false,
      exportShow: false,
      subflag:0,
      pageShow: 0,
      moduleShow: "",
      limit: 10,
      page: 1,
      sorts: "",
      totalCount: 0,
      clientIp: "",
      domain: "",
      account: "",
      description: "",
      valid: "",
      dates: [],
      moduleSelects: "",
      moduleSelectChilds: "",
      actionSelects: "",
      list: [],
      colon: ":",
    };
  },
  created() {
    this.dates.push(
      new Date().toISOString().substr(0, 10),
      new Date().toISOString().substr(0, 10)
    );
    // this.getsortBy("createTime")
  },
  computed: {
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    actionSelect() {
      return [
        { id: "", label: this.$t("label_select_label_all") },
        { id: 10, label: this.$t("label_btn_login") },
        // { id: 20, label: this.$t("label_select_logout") },
        { id: 30, label: this.$t("label_select_add") },
        { id: 40, label: this.$t("label_select_modify") },
        { id: 50, label: this.$t("label_btn_delete") },
      ];
    },
    moduleSelect() {
      return [
        { id: "", label: this.$t("label_select_label_all") },
        {
          id: 10,
          label: this.$t("label_navigation_menu_list_configuration"),
        },
        {
          id: 20,
          label: this.$t("label_navigation_menu_list_management"),
        },
        {
          id: 30,
          label: this.$t("label_select_room_type_conference"),
        },
      ];
    },
    configurationSelectChild() {
      return [
        { id: "", label: this.$t("label_select_label_all") },
        { id: 10, label: this.$t("label_tab_Operator") },
        { id: 20, label: this.$t("label_input_label_email") },
      ];
    },
    managementSelectChild() {
      return [
        { id: "", label: this.$t("label_select_label_all") },
        { id: 30, label: this.$t("label_normal_Domain") },
        { id: 40, label: this.$t("label_table_enterprise_users") },
      ];
    },
    dateRangeText() {
      let dates = this.dates.map((date) => {
        return new moment(date);
      });
      if (dates[0] && dates[1]) {
        if (dates[0] > dates[1]) {
          [dates[0], dates[1]] = [dates[1], dates[0]];
        }
      }
      let renderDates;
      if (this.$vuetify.lang.current === "th") {
        renderDates = dates.map((date) => {
          return date.add(543, "year").format("DD/MM/YYYY");
        });
      } else {
        renderDates = dates.map((date) => {
          return date.format("DD/MM/YYYY");
        });
      }
      return renderDates.join(" ~ ");
    },
    headers() {
      return [
        {
          text: this.$t("label_select_label_operation_data"),
          value: "createTime",
          align: "center",
        },
        {
          text: this.$t("label_normal_Domain"),
          value: "domain",
          align: "center",
        },
        {
          text: this.$t("label_normal_account"),
          value: "account",
          align: "center",
        },
        {
          text: this.$t("label_select_label_module"),
          value: "module",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("label_select_labal_action"),
          value: "action",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("label_description"),
          value: "description",
          align: "center",
          sortable: false,
          cellClass: "des",
        },
        {
          text: this.$t("label_input_label_clientIP"),
          value: "userIp",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  filters: {
    replaceLogString: function (val) {
      let f1 = val.replaceAll(": [", ": ");
      let f2 = f1.replaceAll("], ", "\r\n");
      let f3 = f2.replaceAll("]", " ");
      return f3;
    },
  },
  methods: {
    row(item) {
      this.$emit("handleItem", item);
    },
    moduleSelectClick(id) {
       this.moduleSelectChilds=""
      if (id === 10 || id === 20) {
        this.moduleShow = id;
       
      } else {
        this.moduleShow = false;
      }
    },
    pageUpdate(page) {
      this.page = page;
      this.search();
    },
    limitUpdate(limit) {
      this.limit = limit;
      this.search();
    },
    getsortBy(sort) {
      this.sorts = sort + "_-1";
      this.search();
    },
    getsortDesc(sort) {
      let index = sort.lastIndexOf("_");
      let sortnum = sort.substring(index + 1, sort.length);
      if (sortnum == "1") {
        this.sorts = sort.replace("_1", "_-1");
      } else {
        this.sorts = sort.replace("_-1", "_1");
      }
      this.search();
    },
    async emailValue(emailValue) {
      const parms = {
        m: this.moduleSelects,
        subm: this.moduleSelectChilds,
        ac: this.actionSelects,
        d: this.domain,
        a: this.account,
        ip: this.clientIp,
        k: this.description,
        st: this.toStartTime(new Date(this.dates[0]).getTime()),
        et: this.toEndTime(new Date(this.dates[1]).getTime()),
        sorts: this.sorts,
        email: emailValue,
      };
      try {
        await this.getExportViewlogList(parms);
      } catch (error) {
        process.env.NODE_ENV === "development" && console.error(error);
      }
    },
    async search() {
      this.pageShow = 1;
      this.flagshow = true;
      const limit = this.limit;
      const offset = (this.page - 1) * limit;
      if(this.moduleSelectChilds!=""){
        this.subflag=1
      }else{
        this.subflag=0
      }
      if(this.sorts==""){
        this.sorts="createTime_-1"
      }
      const parms = {
        o: offset,
        l: this.limit,
        m: this.moduleSelects,
        subm: this.moduleSelectChilds,
        ac: this.actionSelects,
        d: encodeURIComponent(this.domain),
        a: encodeURIComponent(this.account),
        ip:encodeURIComponent(this.clientIp),
        k: encodeURIComponent(this.description),
        st: this.toStartTime(new Date(this.dates[0]).getTime()),
        et: this.toEndTime(new Date(this.dates[1]).getTime()),
        sorts: this.sorts,
      };
      try {
        const result = await this.getViewlogList(parms);
        const { total, logs } = result || {};
        this.totalCount = total;
        if (logs) {
          this.list = logs.map(function (item) {
            item.module =
              item.module == 10
                ? "Configuration"
                : item.module == 20
                ? "Management"
                : "Conference";
            item.action =
              item.action == 10
                ? "Login"
                : item.action == 20
                ? "Logout"
                : item.action == 30
                ? "Add"
                : item.action == 40
                ? "Modify"
                : "Delete";
                if(!item.subModule){
                  item.subModule=""
                }else{
                  item.subModule =
              item.subModule == 10
                ? "Operator"
                : item.subModule == 20
                ? "Email"
                : item.subModule == 30
                ? "Domain"
                : "User";
                }
            
            return item;
          });
        }
      } catch (error) {
        console.log();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.btns
  flex-direction: row-reverse
  display: flex
.elevation-1 tr
  cursor: pointer
.elevation-1 tr td span
  cursor: pointer
.des
  cursor: pointer
  width: 20vw
  line-height: 16px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  display: inline-block
.row .col-12
  padding:16px
  box-sizing:border-box
</style>