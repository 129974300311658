<template>
  <v-card height="100%" :disabled="loading">
    <v-card-title class="justify-center">
      <v-card-text>
        <v-row align="center" v-for="(item, index) in nativeItems" :key="index">
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3">
            {{ $t(item.label) }}
          </v-col>
          <v-col cols="12" sm="3" v-html="item.value">
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>
    </v-card-title>
  </v-card>
</template>
<script>
import moment from 'moment';
export default {
  props: ["activeRow"],
  data() {
    return {
      loading: "",
    };
  },
  computed: {
    nativeItems() {
      return [
        {
          label: "label_select_label_operation_data",
          value: this.formatDetailTime(this.activeRow.item.createTime),
        },
        {
          label: "label_normal_Domain",
          value: this.activeRow.item.domain,
        },
        {
          label: "label_normal_account",
          value: this.activeRow.item.account,
        },
        {
          label: "label_select_label_module",
          value: this.activeRow.item.module,
        },
        {
          label: "label_select_labal_action",
          value: this.activeRow.item.action,
        },
        {
          label: "label_description",
          value: this.replaceLogString(this.activeRow.item.description),
        },
        {
          label: "label_input_label_clientIP",
          value: this.activeRow.item.userIp,
        },
      ];
    },
    renderItems() {
      const nativeItems = this.nativeItems;
      const renderItems = nativeItems.filter(
        (item) => item.value !== undefined
      );
      return renderItems;
    },
  },
  methods: {
    formatDetailTime(time) {
      const timeMoment = new moment(time)
      if (this.$vuetify.lang.current === 'th') {
        // buddha hit
        return timeMoment.add(543, 'year').format("DD/MM/YYYY HH:mm:ss")
      }
      return timeMoment.format("DD/MM/YYYY HH:mm:ss")
    },
    replaceLogString(val) {
      let f1 = val.replaceAll(": [", ": ");
      let f2 = f1.replaceAll("], ", "<br>");
      let f3 = f2.replaceAll("]", " ");
      return f3;
    },
  },
};
</script>