<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-18 17:59:04
 * @LastEditTime: 2021-02-07 11:19:06
 * @FilePath: \acuconference-web\src\components\manager\domainManager.vue
-->
<template>
  <v-card
    style="display: flex; flex-direction: column; align-items: center"
    :loading="loading"
  >
    <v-card-text>
      <v-form v-model="formValid" :disabled="disableEdit">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="enterprise.domain"
              maxLength="50"
              :autofocus="false"
              :disabled="domainChecking || disableEditDomain"
              :label="$t('label_normal_Domain') + ' *'"
              :loading="domainChecking"
              :rules="
                domainChecking || disableEditDomain
                  ? requiredRules
                  : rulseDomain
              "
              :error-messages="
                domainValid ? '' : $t('error_prompt_domain_duplicate')
              "
              @blur="checkDomain"
            ></v-text-field>
            <v-text-field
              v-model="enterprise.maxParticipants"
              type="number"
              :label="$t('label_input_label_max_participants') + ' *'"
              :rules="resultMaxParticipants"
              maxLength="11"
              @keydown="KeyboardEvent"
            ></v-text-field>
            <v-text-field
              v-model="enterprise.email"
              maxLength="500"
              type="email"
              :label="$t('label_input_label_email')"
            ></v-text-field>
            <v-select
              :items="exirySelector"
              v-model="expirySelect"
              item-text="label"
              item-value="id"
              :label="$t('label_input_label_domain_expiry')"
            ></v-select>
            <v-date-picker
              v-if="expirySelect === 'selectDate'"
              full-width
              :disabled="disableEdit"
              :landscape="$vuetify.breakpoint.smAndUp"
              v-model="pickerDate"
              :allowed-dates="checkIsAllowed"
              year-icon="mdi-calendar-blank"
              :locale="_i18n.locale"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="enterprise.name"
              :label="$t('label_input_label_domain_name') + ' *'"
              maxLength="500"
              :rules="requiredRules"
            ></v-text-field>
            <div style="display: flex">
              <v-checkbox
                v-model="enterprise.enableRecord"
                :label="$t('label_input_label_enable_conference_record')"
              ></v-checkbox>
              <v-spacer></v-spacer>
              <v-checkbox
                v-model="enterprise.enableAccount"
                :label="$t('label_input_label_enable_account')"
              ></v-checkbox>
              <v-spacer></v-spacer>
            </div>

            <v-text-field
              v-model="enterprise.phone"
              maxLength="500"
              type="tel"
              :label="$t('label_input_label_telephone_no')"
            ></v-text-field>

            <v-text-field
              v-model="enterprise.faxNumber"
              maxLength="500"
              :label="$t('label_fax_number')"
            ></v-text-field>
            <v-text-field
              v-model="enterprise.webPage"
              maxLength="500"
              type="tel"
              :label="$t('label_webpage')"
            ></v-text-field>

            <v-textarea
              :label="$t('label_input_label_address')"
              rows="1"
              maxLength="2000"
              v-model="enterprise.address"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        block
        color="primary"
        :disabled="!couldSave || !showSaveBtn"
        @click="saveDomain"
      >
        {{ $t("label_btn_save") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import apis from "../../mixins/apis";
import fileCheckRegx from "../../mixins/regCheckRulesMixin";
import timeFormatFilter from "../../mixins/timeFormatFilter";
export default {
  mixins: [apis, fileCheckRegx, timeFormatFilter],
  props: {
    activeItem: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      enterprise: {
        maxParticipants: 0,
      },
      expirySelect: "never",
      pickerDate: null,
      disableEditDomain: false,
      domainChecking: false,
      domainValid: true,
      formValid: false,
      hasBeenInit: false,
      showSaveBtn: false,
    };
  },
  watch: {
    "activeItem.id": {
      immediate: true,
      handler() {
        this.getDomainInfo();
      },
    },
    expirySelect(value) {
      if (this.hasBeenInit) {
        this.showSaveBtn = true;
      }
      let targetTime = Date.now();
      switch (value) {
        case "selectDate":
          if (this.enterprise.expireTime) {
            targetTime = this.enterprise.expireTime;
          }
          this.pickerDate = new Date(targetTime).toISOString().substr(0, 10);
          break;
        case "never":
          this.enterprise.expireTime = 0;
          break;
        default:
      }
    },
    pickerDate(val) {
      let afterConvertTime;
      try {
        afterConvertTime = new Date(val);
      } catch (error) {
        process.env.NODE_ENV === "development" && console.log(error);
      }
      if (afterConvertTime) {
        this.enterprise.expireTime = this.toEndTime(afterConvertTime.getTime());
      }
    },
    enterprise: {
      deep: true,
      handler() {
        if (this.hasBeenInit) {
          this.showSaveBtn = true;
        }
      },
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    exirySelector() {
      return [
        { id: "never", label: this.$t("label_select_label_never_expired") },
        { id: "selectDate", label: this.$t("label_select_label_expiry_date") },
      ];
    },
    disableEdit() {
      // 能看见这个的用户只有superadmin和operator，从这个角度来说根本没必要判断，能看见就能编辑
      return false;
      // 企业过期后userType不为100， 200的用户不可编辑
      // const date = Date.now();
      // if (typeof this.enterprise.expireTime === "number") {
      //   if (this.enterprise.expireTime === 0) {
      //     return false;
      //   }
      //   if (date > this.enterprise.expireTime) {
      //     // 企业已过期
      //     const canEditUserType = [100, 200];
      //     if (canEditUserType.includes(this.currentUserInfo.userInfo.type)) {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   } else {
      //     return false
      //   }
      // } else {
      //   return false;
      // }
    },
    couldSave() {
      return (
        this.enterprise.domain &&
        this.domainValid &&
        this.formValid &&
        this.enterprise.name
      );
    },
  },

  methods: {
    KeyboardEvent(e) {
      if (
        e.keyCode === 190 ||
        e.keyCode === 189 ||
        e.keyCode === 109 ||
        e.keyCode === 69 ||
        e.keyCode === 107 ||
        e.keyCode === 187 ||
        e.keyCode === 110 ||
        e.keyCode === 229
      ) {
        e.returnValue = false;
      }
    },
    checkIsAllowed(val) {
      return new Date(val).getTime() >= this.toStartTime(Date.now());
    },
    async getDomainInfo() {
      const { id } = this.activeItem;
      if (id) {
        const result = await this.getEnterpriseById(id);
        this.enterprise = result;
        this.disableEditDomain = true;
        console.log(this.enterprise);
      } else {
        this.enterprise = {
          domain: "",
          maxParticipants: 0,
          email: "",
          phone: "",
          address: "",
          name: "",
          enableRecord: true,
          enableAccount: true,
          sourceEmailId: "",
        };
      }
      // init expirySelector
      this.expirySelect = this.enterprise.expireTime ? "selectDate" : "never";
      this.$nextTick(() => (this.hasBeenInit = true));
    },
    async checkDomain() {
      this.enterprise.domain = this.enterprise.domain.replace(/\s+/g, "");
      if (this.enterprise.domain) {
        this.domainChecking = true;
        const checkResult = await this.checkDomainValid(this.enterprise.domain);
        const { canUse } = checkResult;
        this.domainValid = canUse;
        this.domainChecking = false;
      }
    },
    checkDomainRules() {
      return this.domainValid || "Invalid domain!";
    },
    async saveDomain() {
      const result = await this.saveEditEnterprise(this.enterprise);
      if (result) {
        this.$toast.success(this.$t("prompt_success_domain_saved"));
        this.$nextTick(() => this.$emit("backToDomainPage"));
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.save-btn-block
  position: relative
  width: 20vw
  max-width: 150px
  margin: 20px 0
.flex-box
  position: relative
  flex-grow: 1
</style>