<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-14 18:10:05
 * @LastEditTime: 2021-02-07 17:01:30
 * @FilePath: \acuconference-web\src\views\admin\management.vue
-->
<template>
  <div class="management-wrapper">
    <v-app-bar
      dense
      style="flex-grow: unset"
      class="elevation-0 v-bar--underline"
    >
      <v-tabs
        :class="{
          'config-loading': loading,
        }"
        v-model="currentActiveTabIndex"
      >
        <v-tab v-for="tab in renderTabs" :key="tab.id">
          <span>{{ tab.i18n ? $t(tab.label) : tab.label }}</span>
          <v-btn
            style="margin-left: 10px"
            v-if="tab.canClose"
            icon
            x-small
            @click="closeActiveTab(tab)"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>
    </v-app-bar>

    <v-card v-if="currentActiveTabId === 'domainList'" :disabled="loading">
      <v-card-title>
        <v-btn
          :disabled="!selectColumn.length"
          color="error"
          fab
          small
          :title="$t('label_btn_delete')"
          @click="deleteDomains"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          style="margin-left: 20px"
          fab
          small
          :title="$t('label_btn_add_enterprise')"
          @click="addDomain"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          color="green"
          style="margin-left: 20px"
          :title="$t('label_btn_import_domain')"
          fab
          small
          dark
          @click="uploadDomainFile"
        >
          <v-icon>mdi-upload</v-icon>
        </v-btn>
        <v-btn
          color="green"
          style="margin-left: 20px"
          :title="$t('label_btn_refresh')"
          fab
          small
          :disabled="Boolean(refreshCount)"
          @click="refreshDomainData"
        >
          <v-icon color="white"> mdi-refresh </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          maxLength="500"
          append-icon="mdi-magnify"
          :label="$t('label_search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :disable-sort="true"
        :locale="tableLocal"
        style="width: 100%"
        :items-per-page="limit"
        :loading="loading"
        :headers="headers"
        :items="list"
        :server-items-length="totalCount"
        show-select
        :page="page"
        @update:page="pageUpdate"
        @update:items-per-page="limitUpdate"
        :footer-props="tableFooterOptions"
        v-model="selectColumn"
      >
        <template v-slot:no-data>
          <span>
            {{ $t("table_no_data") }}
          </span>
        </template>
        <template v-slot:item.domain="{ item }">
          <v-btn
            text
            color="primary"
            class="limit-btn"
            @click="showThisEnterprice(item)"
          >
            {{ item.domain }}
          </v-btn>
        </template>
        <template v-slot:item.userSize="{ item }">
          <v-btn text color="primary" @click="showThisEnterpriceUsers(item)">
            {{ item.userSize }}
          </v-btn>
        </template>
        <template v-slot:item.enableAccount="{ item }">
          <v-simple-checkbox
            disabled
            :ripple="false"
            :value="item.enableAccount"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.createTime="{ item }">
          <span>
            {{ formatTime(item.createTime) }}
          </span>
        </template>
        <template v-slot:item.expireTime="{ item }">
          <span v-if="item.expireTime == 0">
            {{ $t("label_select_label_never_expired") }}
          </span>
          <span v-else>
            {{ formatTime(item.expireTime) }}
          </span>
        </template>
        <template v-slot:item.management="{ item }">
          <v-btn
            dark
            small
            depressed
            color="primary"
            style="margin-right: 10px"
            @click="showThisEnterpriceUsers(item, true)"
          >
            {{ $t("label_btn_add_user") }}
          </v-btn>
          <v-btn
            dark
            small
            color="primary"
            @click="showThisEnterpriceUsers(item)"
          >
            {{ $t("label_btn_user_list") }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <DomainManager
      :activeItem="activeRow.item"
      @backToDomainPage="backToDomainPage"
      v-else-if="activeRow.type && currentActiveTabId === 'featureTabDomain'"
    />
    <UserList
      :activeItem="activeRow.item"
      :addUserAction="addUserAction"
      @used="addUserAction = false"
      v-else-if="activeRow.type && currentActiveTabId === 'featureTabUsers'"
    />
    <Information
      v-else-if="activeRow.type && currentActiveTabId === 'information'"
      :activeRow="activeRow"
    />
    <UploadUserFileDialog
      @refreshUserList="getNewData"
      v-model="showUploadDomainDialog"
      :importType="'domain'"
      :template="'/import_domain_sample.xlsx'"
    />
    <ViewLog
      v-show="currentActiveTabId === 'viewlogList'"
      @handleItem="handleItem"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import apis from "../../mixins/apis";
import timeFormatFilter from "../../mixins/timeFormatFilter";
import importDomainTemplate from "../../assets/import_domain_sample.xlsx";
import DomainManager from "../../components/manager/domainManager";
import UserList from "../../components/manager/userListManager";
import UploadUserFileDialog from "../../components/dialogs/importUsers";
import ViewLog from "../../components/viewlog/viewlogManager";
import Information from "../../components/viewlog/information";
export default {
  mixins: [apis, timeFormatFilter],
  components: {
    DomainManager,
    UserList,
    UploadUserFileDialog,
    ViewLog,
    Information,
  },
  data() {
    return {
      currentActiveTabIndex: 0,
      currentActiveTabId: "",
      importDomainTemplate,
      limit: 10,
      list: [],
      page: 1,
      totalCount: 0,
      search: "",
      searchTimer: 0,
      selectColumn: [],
      addUserAction: false,
      showUploadDomainDialog: false,
      activeRow: {
        type: "",
        item: {},
      },
      refreshCount: 0,
      refreshTimer: 0,
    };
  },
  computed: {
    featureTabs() {
      const tabs = [];
      let featureTab;
      if (this.activeRow.type) {
        switch (this.activeRow.type) {
          case "domain":
            featureTab = {
              id: "featureTabDomain",
              canClose: true,
              label: this.activeRow.item.name,
            };
            if (this.activeRow.init) {
              featureTab.label = this.$t("label_new_enterprise");
            }
            break;
          case "users":
            if (this.showManageDomainPage) {
              featureTab = {
                id: "featureTabUsers",
                canClose: true,
                label: this.$t("label_tab_users_of_domain", {
                  domain: this.activeRow.item.name,
                }),
              };
            }
            break;
          case "information":
            if (this.showManageDomainPage) {
              featureTab = {
                id: "information",
                canClose: true,
                label: this.$t("label_log_Information"),
              };
            }
            break;
          default:
            break;
        }
      }
      if (featureTab) {
        tabs.push(featureTab);
      }
      return tabs;
    },
    headers() {
      return [
        {
          text: this.$t("label_normal_Domain"),
          value: "domain",
          align: "center",
        },
        {
          text: this.$t("label_input_label_domain_name"),
          value: "name",
          align: "center",
        },
        {
          text: this.$t("label_table_enterprise_users"),
          value: "userSize",
          align: "center",
        },
        {
          text: this.$t("label_table_enterprise_create_date"),
          value: "createTime",
          align: "center",
        },
        {
          text: this.$t("label_table_enterprise_expire_date"),
          value: "expireTime",
          align: "center",
        },
        {
          text: this.$t("label_input_label_enable_account"),
          value: "enableAccount",
          align: "center",
        },
        {
          text: this.$t("label_title_user_management"),
          value: "management",
          align: "center",
        },
      ];
    },
    showManageDomainPage() {
      return this.currentUserInfo.userInfo.type !== 300;
    },
    nativeTabs() {
      const tabs = [];
      if (this.showManageDomainPage) {
        tabs.push(
          {
            id: "domainList",
            i18n: true,
            label: "label_normal_Domain",
          },
          {
            id: "viewlogList",
            i18n: true,
            label: "label_normal_ViewLog",
          }
        );
      } else {
        tabs.push({
          id: "featureTabUsers",
          label: this.$t("label_btn_user_list"),
        });
      }
      const featureTabs = this.featureTabs;
      return tabs.concat(featureTabs);
    },
    renderTabs() {
      const tab = this.nativeTabs;
      return tab;
    },
    ...mapState("userInfo", ["currentUserInfo"]),
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getNewData();
      },
    },
    limit() {
      this.getNewData();
    },
    showManageDomainPage: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.activeRow = {
            type: "users",
            item: {
              id: this.currentUserInfo.userInfo.enterpriseId,
            },
          };
        }
      },
    },
    currentActiveTabIndex: {
      immediate: true,
      handler(val) {
        const activeTab = this.renderTabs[val];
        this.currentActiveTabId = activeTab.id;
      },
    },
    showUploadDomainDialog(val) {
      if (!val) {
        this.getNewData();
      }
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.getNewData();
        }
      }, 200);
    },
  },
  methods: {
    handleItem(item) {
      this.activeRow = {
        type: "information",
        item,
      };
      this.$nextTick(() => {
        this.currentActiveTabIndex = 2;
      });
    },
    refreshDomainData() {
      clearInterval(this.refreshTimer);
      this.refreshCount = 3;
      this.refreshTimer = setInterval(() => {
        this.refreshCount -= 1;
        if (this.refreshCount <= 0) {
          this.refreshCount = 0;
          clearInterval(this.refreshTimer);
        }
      }, 1000);
      this.getNewData();
    },
    async getNewData() {
      if (!this.showManageDomainPage) {
        return;
      }
      //  items per page will be -1 if user select "All"
      const limit = this.limit;
      const offset = (this.page - 1) * limit;
      const result = await this.getListsFromServer(
        process.env.VUE_APP_API_GET_DOMAINS,
        {
          o: offset,
          l: limit,
          k: encodeURIComponent(this.search),
        }
      );
      const { enterprises, total } = result;
      this.list = enterprises;
      this.totalCount = total;
    },
    uploadDomainFile() {
      this.showUploadDomainDialog = true;
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    showThisEnterprice(item) {
      this.activeRow = {
        type: "domain",
        item,
      };
      this.$nextTick(() => {
        this.currentActiveTabIndex = 2;
      });
    },
    showThisEnterpriceUsers(item, addUser) {
      this.activeRow = {
        type: "users",
        item,
      };
      this.addUserAction = addUser;
      this.$nextTick(() => {
        this.currentActiveTabIndex = 2;
      });
    },
    addDomain() {
      this.activeRow = {
        type: "domain",
        item: {},
        init: true,
      };
      this.$nextTick(() => {
        this.currentActiveTabIndex = 2;
      });
    },
    async deleteDomains() {
      const confirm = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirm !== "confirm") {
        return;
      }
      const ids = this.selectColumn.map((item) => {
        return item.id;
      });
      if (ids.includes(this.activeRow.item.id)) {
        this.closeActiveTab();
      }
      const result = await this.deleteDomainsApi({
        ids,
      });
      if (result) {
        this.$toast.success(this.$t("prompt_success"));
        this.$nextTick(() => {
          this.selectColumn = [];
        });
        this.getNewData();
      }
    },
    closeActiveTab() {
      if(this.activeRow.type==="information"){
        this.currentActiveTabIndex=1
      }else{
        this.currentActiveTabIndex = 0;
      }
      
      this.$nextTick(() => {
        this.activeRow = {
          type: "",
          item: {},
        };
      });
      this.refreshDomainData();
    },
    backToDomainPage() {
      if (this.showManageDomainPage) {
        this.closeActiveTab();
        this.getNewData();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.management-wrapper
  position: relative
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .config-loading
    filter: grayscale(1)
    pointer-events: none
  .container
    position: relative
    flex-grow: 1
    width: 100%
    display: flex
    justify-content: center
    &>div
      width: 100%
</style>