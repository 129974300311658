<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-29 16:44:47
 * @LastEditTime: 2021-02-07 16:42:34
 * @FilePath: \acuconference-web\src\components\dialogs\importUsers.vue
-->
<template>
  <v-dialog v-model="showImportUsers" max-width="600" :persistent="true">
    <v-card
      :loading="importUnfinished && uploadingFile"
      :disabled="uploadingFile"
    >
      <v-card-title>
        {{ $t("label_title_upload_" + importType) }}
      </v-card-title>
      <v-card-text
        v-if="
          !uploadFinish &&
          (importType === 'user' ? enterpriseInfo.domain : true)
        "
      >
        <div class="dropzone-wrapper">
          <vue2DropZone
            id="dropzone"
            :options="dropzoneOptions"
            ref="dropZone"
            @vdropzone-success="handleUploadSuccess"
            @vdropzone-error="handleUploadError"
            @vdropzone-canceled="handleUploadError"
            @vdropzone-file-added="startLoading"
          />
          <div v-if="!!uploadResponseId" class="please-wait-for-success">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <span>
              {{ $t("label_prompt_wait_for_server") }}
            </span>
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-card>
          <v-card-subtitle>
            {{ $t("label_upload_finish") }}
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("label_success") }}
                </v-col>
                <v-col cols="12" md="6">
                  {{ uploadFinish && uploadFinish.successCount }}
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  {{ $t("label_failed") }}
                </v-col>
                <v-col cols="12" md="6">
                  {{ uploadFinish && uploadFinish.failedCount }}
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="showFailedDetailDialog = true"
                    v-if="uploadFinish && uploadFinish.failedList.length > 0"
                  >
                    {{ $t("label_detail") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="uploadFinish = null">
              {{ $t("label_btn_back_to_upload") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn text color="green" :href="template">{{
          $t("label_btn_download_template")
        }}</v-btn> -->
         <a style="text-decoration: none;color: green; font-size: 16px;" :href="template">{{
          $t("label_btn_download_template")
        }}</a>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="showImportUsers = false">
          {{ $t(!!uploadResponseId ? "label_btn_close" : "label_btn_cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showFailedDetailDialog" max-width="400">
      <v-card>
        <v-card-title>
          {{ $t("label_failed_list") }}
        </v-card-title>
        <v-card-text v-if="uploadFinish">
          <v-data-table
            :headers="failedDataHeader"
            :items="uploadFinish.failedList"
            disable-sort
            :hide-default-footer="true"
          >
            <template v-slot:no-data>
              <span>
                {{ $t("table_no_data") }}
              </span>
            </template>
            <template v-slot:item.code="{ item }">
              <span>{{ renderFailedItem(item) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import apis from "../../mixins/apis";
import uploadMixin from "../../mixins/uploadMixin";
import vue2DropZone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState } from "vuex";
export default {
  mixins: [apis, uploadMixin],
  components: {
    vue2DropZone,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Object,
      default() {
        return {};
      },
    },
    importType: {
      type: String,
      default: "user",
    },
    template: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showImportUsers: false,
      showFailedDetailDialog: false,
      loading: true,
      enterpriseInfo: {},
      uploadFinish: null,
      uploadResponseId: "",
      uploadingFile: false,
      importUnfinished: true,
    };
  },
  watch: {
    value(val) {
      if (val !== this.showImportUsers) {
        this.showImportUsers = val;
      }
      if (!val) {
        this.initImport();
      }
    },
    showImportUsers(val) {
      if (val !== this.value) {
        this.$emit("input", val);
      }
      if (val) {
        this.getEnterpriseInfo();
      }
    },
  },
  computed: {
    ...mapState("userInfo", ["currentUserInfo"]),
    dropzoneOptions() {
      const options = {
        thumbnailWidth: 150,
        maxFileSize: 1 * 100,
        maxFiles: 1,
        dictDefaultMessage: this.$t("label_drop_file_upload"),
        acceptedFiles: ".xlsx",
        timeout: 60 * 60 * 1000,
        method: "post",
        headers: {
          acutoken: this.currentUserInfo.acutoken,
        },
      };
      if (this.importType === "user") {
        options.url =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_UPLOAD_USER_FILE +
          "?d=" +
          this.enterpriseInfo.domain;
      } else if (this.importType === "domain") {
        options.url =
          process.env.VUE_APP_API_HOST +
          process.env.VUE_APP_API_UPLOAD_DOMAIN_FILE;
      }
      return options;
    },
    failedDataHeader() {
      let headers = {};
      headers = [
        {
          text: this.$t("label_header_reason"),
          value: "code",
        },
        {
          text: this.$t("label_header_number"),
          value: "count",
        },
      ];
      return headers;
    },
  },
  mounted() {},
  created() {},
  methods: {
    initImport() {
      try {
        this.importUnfinished = true;
        this.uploadingFile = false;
        this.uploadResponseId = "";
        this.uploadFinish = null;
        clearTimeout(this.responseCheckTimer);
        this.$refs.dropZone.removeAllFiles();
      } catch (error) {
        process.env.NODE_ENV === "production" && console.error(error);
      }
    },
    renderFailedItem(item) {
      let msg = "";
      if (item && item.code) {
        if (this.importType === "user") {
          switch (Number(item.code)) {
            case 6024:
              msg = "error_import_user_send_email_failed";
              break;
            case 6027:
              msg = "error_import_user_account_exist";
              break;
            case 6029:
              msg = "error_import_user_email_used";
              break;
            case 6030:
              msg = "error_import_user_account_null";
              break;
            case 6035:
              msg = "error_length_chaoguole_account";
              break;
              case 6039:
              msg = "error_max_participants_exceed_limit";
              break;
              case 6040:
              msg = "error_max_participants_is_null";
              break;
              case 6044:
              msg = "error_code_msg";
              break;
              //  case 6042:
              // msg = "error_please_use_our_template";
              // break;
            default:
              msg = "error_import_user_server_error";
              break;
          }
        } else if (this.importType === "domain") {
          switch (Number(item.code)) {
            case 6032:
              msg = "error_import_domain_domain_exists";
              break;
            case 6033:
              msg = "error_import_domain_domain_none";
              break;
            case 6035:
              msg = "error_length_chaoguole_domain";
              break;
               case 6042:
              msg = "error_please_use_our_template";
              break;
            default:
              msg = "error_import_user_server_error";
              break;
          }
        }
      }
      if (msg) {
        return this.$t(msg);
      }
    },
    async getEnterpriseInfo() {
      if (this.enterprise.id) {
        const result = await this.getEnterpriseById(this.enterprise.id);
        if (result) {
          this.enterpriseInfo = result;
        }
      }
    },
    startLoading() {
      this.importUnfinished = true;
      this.uploadingFile = true;
    },
    handleUploadSuccess(_, res) {
      // will get id from res, interval check id
      const { code, data } = res;
      if (code !== 1) {
        this.$errorTranslate(res);
      } else {
        try {
          this.$refs.dropZone.removeAllFiles();
        } catch (error) {
          process.env.NODE_ENV === "production" && console.error(error);
        }
        this.$nextTick(() => {
          this.uploadResponseId = data.resultId;
          this.uploadingFile = false;
          this.startIntervalCheckResponse();
        });
      }
      // const { code, data } = res;
      // this.uploadFinish = null;
      // if (code !== 1) {
      //   this.$errorTranslate(res);
      // } else {
      //   const { failedList, updated } = data;
      //   this.uploadFinish = {
      //     successCount: updated,
      //     failedCount: failedList.length,
      //     failedList,
      //   };
      //   if (updated > 0) {
      //     this.$emit("refreshUserList");
      //   }
      // }
      // try {
      //   this.$refs.dropZone.removeAllFiles();
      // } catch (error) {
      //   process.env.NODE_ENV === "production" && console.error(error);
      // }
      // if (this.uploadFinish && (this.uploadFinish.failedCount === 0)) {
      //   this.$toast.success(this.$t("prompt_success"));
      //   this.$nextTick(() => {
      //     this.showImportUsers = false;
      //   });
      // }
    },
    handleUploadError(_, res) {
      this.uploadFinish = null;
      res = res || {
        msg: "Upload Canceled",
      };
      let error = this.$errorTranslate(res)
      if (error) {
        this.$toast.error(error);
      }
      this.initImport();
    },
  },
};
</script>

<style lang="sass" scoped>
.dropzone-wrapper
  position: relative
  width: 100%
  height: 100%
  .please-wait-for-success
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background: white
    span
      margin-top: 16px
</style>