<!--
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-01-18 17:59:12
 * @LastEditTime: 2021-02-07 12:30:22
 * @FilePath: \acuconference-web\src\components\manager\userListManager.vue
-->
<template>
  <v-card>
    <v-card-title>
      <v-btn
        :disabled="!selectColumn.length"
        color="error"
        :title="$t('label_btn_delete')"
        fab
        small
        @click="confirmDeleteUser"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        color="primary"
        style="margin-left: 20px"
        :title="$t('label_btn_add_user')"
        fab
        small
        @click="editUser"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        color="green"
        style="margin-left: 20px"
        :title="$t('label_btn_import_user')"
        fab
        small
        dark
        @click="uploadUserFile"
      >
        <v-icon>mdi-upload</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        maxLength="500"
        append-icon="mdi-magnify"
        :label="$t('label_search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :disable-sort="true"
      :locale="tableLocal"
      style="width: 100%"
      :items-per-page="limit"
      :loading="loading"
      :headers="headers"
      :items="list"
      :server-items-length="totalCount"
      show-select
      :page="page"
      @update:page="pageUpdate"
      @update:items-per-page="limitUpdate"
      :footer-props="tableFooterOptions"
      v-model="selectColumn"
    >
      <template v-slot:no-data>
        <span>
          {{ $t("table_no_data") }}
        </span>
      </template>
      <template v-slot:item.account="{ item }">
        <v-btn text color="primary" @click="editUser(item)">{{
          item.account
        }}</v-btn>
      </template>
      <template v-slot:item.enableAccount="{ item }">
        <v-simple-checkbox
          disabled
          :ripple="false"
          :value="item.enableAccount"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.type="{ item }">
        <v-simple-checkbox
          disabled
          :ripple="false"
          :value="item.type === adminType"
        ></v-simple-checkbox>
      </template>
    </v-data-table>
    <Profile
      v-model="showEditUserDialog"
      :activeUser="activeUser"
      :enterpriseId="activeItem.id"
      @finish="initUsers"
      @refreshSelfData="refreshCurrentUserData"
    />
    <UploadUserFileDialog
      @refreshUserList="initUsers"
      v-model="showUploadUserDialog"
      :enterprise="activeItem"
      :importType="'user'"
      :template="'/import_users_sample.xlsx'"
    />
  </v-card>
</template>

<script>
import apis from "../../mixins/apis";
import Profile from "../dialogs/profile";
import UploadUserFileDialog from "../dialogs/importUsers";
import uploadUserTemplate from "../../assets/import_users_sample.xlsx";
import { mapActions } from "vuex";
export default {
  mixins: [apis],
  components: {
    Profile,
    UploadUserFileDialog,
  },
  props: {
    activeItem: {
      type: Object,
      default() {
        return {};
      },
    },
    addUserAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getPage() {
      return Math.max(this.page + 1, 0);
    },
    headers() {
      return [
        {
          text: this.$t("label_user_account"),
          value: "account",
          align: "center",
        },
        {
          text: this.$t("label_user_name"),
          value: "name",
          align: "center",
        },
        {
          text: this.$t("label_user_email"),
          value: "email",
          align: "center",
        },
        {
          text: this.$t("label_user_enabled"),
          value: "enableAccount",
          align: "center",
        },
        {
          text: this.$t("label_user_administrator"),
          value: "type",
          align: "center",
        },
      ];
    },
    adminType() {
      return 300;
    },
  },
  created() {
    if (this.addUserAction) {
      this.addUserAfterInit = true;
      this.$emit("used");
    }
  },
  data() {
    return {
      list: [],
      page: 1,
      uploadUserTemplate,
      limit: 10,
      totalCount: 0,
      selectColumn: [],
      search: "",
      searchTimer: 0,
      activeUser: null,
      showEditUserDialog: false,
      addUserAfterInit: false,
      showUploadUserDialog: false,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.initUsers();
      },
    },
    limit() {
      this.initUsers();
    },
    showUploadUserDialog(val) {
      if (!val) {
        this.initUsers();
      }
    },
    activeUser(val) {
      if (!!val !== this.showEditUserDialog) {
        this.showEditUserDialog = !!val;
      }
    },
    showEditUserDialog(val) {
      if (!val) {
        this.activeUser = null;
      }
    },
    search() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(() => {
        if (this.page !== 1) {
          this.page = 1;
        } else {
          this.initUsers();
        }
      }, 200);
    },
  },
  methods: {
    ...mapActions("userInfo", ["refreshCurrentUserData"]),
    async initUsers() {
      const { id } = this.activeItem;
      let offset = (this.page - 1) * this.limit,
        limit = this.limit,
        enterpriseId;
      if (id) {
        enterpriseId = id;
        const result = await this.getListsFromServer(
          process.env.VUE_APP_API_GET_USERS,
          {
            o: offset,
            l: limit,
            e: enterpriseId,
            k: encodeURIComponent(this.search),
          }
        );
        if (result) {
          const { users, total } = result;
          this.list = users;
          this.totalCount = total;
        }
      }
      if (this.addUserAfterInit) {
        this.addUserAfterInit = false;
        this.editUser();
      }
    },
    async confirmDeleteUser() {
      const ids = this.selectColumn.map((column) => {
        return column.id;
      });
      const confirmDelete = await this.$confirm.warn(
        this.$t("label_delete_confirm"),
        {
          title: this.$t("label_warn"),
          buttons: [
            {
              label: this.$t("label_btn_cancel"),
              value: "cancel",
              color: "primary",
            },
            {
              label: this.$t("label_btn_confirm"),
              value: "confirm",
              color: "primary",
            },
          ],
        }
      );
      if (confirmDelete === "confirm") {
        const result = await this.deleteUser({
          ids: ids,
        });
        if (result) {
          // set page to 0 to refresh this page
          this.$toast.success(this.$t("prompt_success"));
          this.$nextTick(() => {
            this.initUsers();
          });
        }
        this.selectColumn = [];
      }
    },
    uploadUserFile() {
      this.showUploadUserDialog = true;
    },
    pageUpdate(page) {
      this.page = page;
    },
    limitUpdate(limit) {
      this.limit = limit;
    },
    editUser(user = {}) {
      if (user.id) {
        this.activeUser = user;
      } else {
        this.activeUser = {
          account: "",
          email: "",
          type: "",
          address: "",
          name: "",
          enableAccount: true,
          enableRecord: "",
          phone: "",
          mobile: "",
          password: "",
        };
      }
    },
  },
};
</script>

<style lang="sass" scoped>
</style>