/*
 * @Author: ZerroRt
 * @lastEditors: ZerroRt
 * @Date: 2021-02-04 11:29:16
 * @LastEditTime: 2021-02-04 15:52:43
 * @FilePath: \acuconference-web\src\mixins\uploadMixin.js
 */
import { repla } from "../utils/stringUtils"
const STATUS_SUCCESS = 1
const STATUS_PROGRESSING = 0
export default {
  data() {
    return {
      responseCheckTimer: 0,
    }
  },
  methods: {
    startIntervalCheckResponse() {
      clearTimeout(this.responseCheckTimer)
      this.responseCheckTimer = setTimeout(() => {
        if (this.uploadResponseId) {
          this.checkUploadStatus();
        }
      }, 2 * 1000)
    },
    setResult(data) {
      const { successCount, failedResult } = data
      let count = 0
      failedResult.forEach(failed => {
        count += failed.count
      })
      this.uploadResponseId = ""
      this.importUnfinished = false
      this.$nextTick(() => {
        this.uploadFinish = {
          successCount,
          failedCount: count,
          failedList: failedResult,
        }
      })
    },
    async checkUploadStatus() {
      const api = repla(process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_GET_IMPORT_RESULT, {
        id: this.uploadResponseId
      })
      try {
        const result = await this.$axios.get(api)
        if (result && result.code === 1) {
          const { data } = result
          switch (data.status) {
            case STATUS_SUCCESS:
              this.setResult(data)
              return
            case STATUS_PROGRESSING:
              this.startIntervalCheckResponse()
              return
            default:
              break;
          }
        }
      } catch (error) {
        process.env.NODE_ENV === 'development' && console.error(error)
      }
    },
  },
}